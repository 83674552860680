import React from "react"

import RichTextSlice from "../components/slices/RichTextSlice"
import StepSlice from "../components/slices/StepSlice"
import VideoSlice from "../components/slices/VideoSlice"
import ImageSlice from "../components/slices/ImageSlice"
import QuoteSlice from "../components/slices/QuoteSlice"
import AdviceSlice from "../components/slices/AdviceSlice"
import GearSlice from "../components/slices/GearSlice"
import QuestionSlice from "../components/slices/QuestionSlice"

const PrintBlock = (props) => {
    const {
        slice, 
        step
    } = props
    // console.log(slice)

    if ( slice.slice_type === "rich_text" ) {
        return <RichTextSlice 
            html={slice.primary.rich_text.html} 
            key={slice.id}
        />
    }

    if ( slice.slice_type === "step" ) {
        return <StepSlice 
            key={slice.id}
            count={step}
            title={slice.primary.step_title.text}
            html={slice.primary.rich_text.html} 
        />
    }

    if ( slice.slice_type === "image" ) {
        return (
            <ImageSlice 
                key={slice.id}
                image={slice.primary.image} 
                alt={slice.primary.image.alt} 
            />
        )
    }

    if ( slice.slice_type === "video" ) {
        return (
            <VideoSlice 
                key={slice.id}
                html={slice.primary.embed.html} 
            />
        )
    }

    if ( slice.slice_type === "quote" ) {
        return (
            <QuoteSlice 
                key={slice.id}
                quote={slice.primary.quote.html}
                source_url={slice.primary.source_url}
                source={slice.primary.source}
            />
        )
    }

    if ( slice.slice_type === "question" ) return <QuestionSlice question={slice.primary.question.document.data} key={slice.id} />

    if ( slice.slice_type === "advice" ) {
        const items = slice.items.map( item => ({
            'text': item.advice.text,
            'html': item.advice.html,
            'type': item.type,
        }))

        return (
            <AdviceSlice
                key={slice.id} 
                items={items}
            />
        )
    } 

    if ( slice.slice_type === "gear" ) return <GearSlice items={slice.items} key={slice.id} />

    return null
  }

export default PrintBlock