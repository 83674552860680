import React from "react"

const RichTextSlice = (props) => {
    const {
        html
    } = props

    return (
        <div className="container">
            <div 
                dangerouslySetInnerHTML={{ __html: html }} 
            />
        </div>
    )
}

export default RichTextSlice