import React from "react"

import { css } from '@emotion/react'
import { colors } from '../styles/constants'

const QuestionCard = (props) => {
    const {
        title, 
        tldr,
        goals,
    } = props

    return (
        <div css={main}>
            <div css={tab}>F.A.Q</div>
            <div css={meta}>
                {goals && <h4>{goals}</h4>}
                <h3>{title}</h3>
            </div>
            <div css={content}>
                <p>{tldr}</p>
            </div>
        </div>
    )
}

export default QuestionCard

const main = css`
    max-width: 500px;
    margin: 20px auto;
    border: 1px solid ${colors.lightgrey};
    background-color: white;
    border-radius: 10px;
    position: relative;

    h4 {
        font-size: .8em;
        margin: 0;
    }

    h3 {
        font-size: 1.4em;
        margin: 0;
    }

    p {
        margin: 0;
    }
`

const meta = css`
    padding: 20px;
    background-color: ${colors.whitegrey};
    border-radius: 10px 10px 0 0;
`

const content = css`
    padding: 20px;
`

const tab = css`
    position: absolute;
    top: -31px;
    left: 20px;
    padding: 5px 10px;
    height: 30px;
    background-color: ${colors.black};
    color: white;
    border-radius: 10px 10px 0 0;
    line-height: 1;
`