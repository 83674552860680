import React from "react"

import { GatsbyImage } from "gatsby-plugin-image"

import { css } from '@emotion/react'
import { colors } from '../../styles/constants'

const GearSlice = (props) => {
    const {
        items
    } = props

    return (
        <div>
            <div className="container">
                <div className="grid--two-by-two">
                    {items.map( item => {
                        const product = item.gear.document

                        if ( product ) {
                            const uid = product.uid
    
                            if ( product.__typename === "PrismicGear" ) {
                                const images = product.gear_data.images
                                const name = product.gear_data.name.text
                                const price = product.gear_data.price
                                const currency = product.gear_data.currency
                                const desc = product.gear_data.desc.text
                                const url = product.gear_data.link ? product.gear_data.link.url : null
    
                                return (
                                    <div 
                                        key={uid}
                                        css={productCard}
                                    >
                                        {images.map( (item, index) => {
                                            return (
                                                <GatsbyImage image={item.image.gatsbyImageData}
                                                    alt={name}
                                                    key={index}
                                                />
                                            )
                                        })}
    
                                        <div className="card-container">
                                            <h3>{name}</h3>
    
                                            {price && 
                                                <div className="product__price">
                                                    A partir de <span>{price}</span> {currency || "CHF"}
                                                </div>
                                            }
    
                                            <p>{desc}</p>
    
                                            {url && 
                                                <div>
                                                    <a href={url} 
                                                        target="_blank"
                                                        rel="noreferrer noopener nofollow"
                                                    >
                                                        Voir plus
                                                    </a>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                )
                            }
    
                            if ( product.__typename === "PrismicTool" ) {
                                const name = product.tool_data.name.text
                                const cover = product.tool_data.cover.gatsbyImageData
                                const prices = product.tool_data.prices[0] ? product.tool_data.prices : null
                                const desc = product.tool_data.desc
                                const url = product.tool_data.link ? product.tool_data.link.url : null
    
                                return (
                                    <div 
                                        key={uid}
                                        css={productCard}
                                    >
                                        {cover && <GatsbyImage image={cover}  alt={name} />}
                                        <div className="card-container">
                                            <h3>{name}</h3>
    
                                            {prices && prices.map( (item, index) => {
                                                const { plan_name: plan, price, currency, monthly } = item
                                                // console.log("Prices", item)
                                                return (
                                                    <div className="product__price" key={`${plan}-${index}`} >
                                                        {price === 0 ? 
                                                            plan
                                                            :
                                                            `${plan || "Payant"} (${price} ${currency}${monthly ? " /mois" : ""})`
                                                        }
                                                    </div>
                                                )
                                            })}
    
                                            <p>{desc}</p>
    
                                            {url && 
                                                <div>
                                                    <a href={url} 
                                                        target="_blank"
                                                        rel="noreferrer noopener nofollow"
                                                    >
                                                        Voir plus
                                                    </a>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                )
                            }
                            return false
                        }
                        return false
                    })}
                </div>
            </div>
        </div>
    )
}

export default GearSlice

const productCard = css`
    border-radius: 5px;
    box-shadow: ${colors.shadow};
    border: 1px solid ${colors.whitegrey};
    overflow: hidden;

    .card-container {
        padding: 20px;
    }

    h3 {
        margin: 0;
        font-size: 1.3em;
    }

    div.product__price {
        font-size: 1em;
        color: ${colors.midgrey};

        span {
            font-weight: 700;
        }
    }

    p {
        margin: 10px 0 0;
        font-size: .9em;
        line-height: 1.4;
    }

    a {
        margin-top: 10px;
        display: inline-block;
        padding: 5px 10px;
        border: 1px solid ${colors.accent};
        border-radius: 5px;

        :hover {
            text-decoration: none;
            background-color: ${colors.accent};
            color: white;
        }
    }
`