import React from "react"

import { css } from '@emotion/react'
import { colors } from '../../styles/constants'

const QuoteSlice = (props) => {
    const {
        quote,
        source_url,
        source,
    } = props

    return (
        <div css={main}>
            <div className="container">
                <span className="quotation-mark">"</span>

                <blockquote dangerouslySetInnerHTML={{ __html: quote }} />

                {source && 
                    <div>
                        {source_url ?
                            <a href={source_url} 
                                rel="nofollow noopener noreferrer" 
                                target="_blank"
                            >
                                {source}
                            </a>
                            :
                            source
                        }
                    </div>
                }
            </div>
        </div>
    )
}

export default QuoteSlice

const main = css`
    background-color: ${colors.tv1};
    padding-top: 100px;
    padding-bottom: 100px;
    margin-top: 60px;

    .container {
        position: relative;

        .quotation-mark {
            position: absolute;
            top: 80px;
            left: -40px;
            font-size: 20em;
            color: rgba(0, 0, 0, .11); 
        }
    }

    blockquote {
        margin-bottom: 0;
        p {
            margin-bottom: 0;
            font-size: 1.8em;
            line-height: 1.3;
        }
    }
`