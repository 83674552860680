import React from "react"

import { css } from '@emotion/react'
import { colors } from '../../styles/constants'

const StepSlice = (props) => {
    const {
        count,
        title,
        html,
    } = props

    return (
        <div 
            css={stepSlice}
            className="container"
        >
            <div className="step__header">
                <div className="step-subtitle"><span>{count}</span></div>
                <h2>{title}</h2>
            </div>

            <div 
                dangerouslySetInnerHTML={{ __html: html }} 
            />
        </div>
    )
}

export default StepSlice

const stepSlice = css`
    margin-top: 66px;
    margin-bottom: 50px;

    .step__header {
        display: flex;
        justify-content: flex-start;
        align-items: center;
    }

    .step-subtitle {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100px;
        width: 100px;
        min-width: 100px;
        margin-bottom: 20px;
        background-color: ${colors.black};
        border-radius: 20px 20px 20px 5px;

        span {
            font-size: 4.5em;
            color: ${colors.white};
            text-transform: uppercase;
            font-weight: 700;
            line-height: 1;
            margin-top: -5px;
        }
    }
    
    h2 {
        margin-top: 0;
        margin-left: 20px;
    }
`