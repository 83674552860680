import React from "react"

import { MdCheckCircle, MdCancel } from "react-icons/md"

import { css } from '@emotion/react'
import { colors } from '../../styles/constants'

const AdviceSlice = (props) => {
    const {
        items
    } = props

    return (
        <div css={main}>
            <div className="container">
                <div className="advice__box" >
                    <h3>Quelques conseils</h3>

                    {items.map( item => {
                        const {
                            text,
                            html,
                            type,
                        } = item

                        return (
                            <div 
                                key={text} 
                                className="advice__item"
                                style={type === "Do" ? {color: `green`} : {color: `red`}}
                            >
                                {type === "Do" ? <MdCheckCircle /> : <MdCancel />}
                                <div className="advice__text" dangerouslySetInnerHTML={{ __html: html }} />
                            </div>
                        )
                    })}
                </div>
            </div>
        </div>
    )
}

export default AdviceSlice

const main = css`
    background-color: ${colors.tv3};
    padding-top: 66px;
    padding-bottom: 66px;
    margin-top: 66px;
    margin-bottom: 66px;

    .advice__box {
        background-color: ${colors.white};
        max-width: 700px;
        padding: 20px 20px 10px;
        border: 1px solid ${colors.whitegrey};
        border-radius: 10px;
        margin: 50px auto;

        h3 {
            margin: 0 0 20px;
        }

        .advice__item {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            background-color: ${colors.whitegrey};
            border-radius: 5px;
            margin-bottom: 10px;
            padding: 10px;

            svg {
                min-width: 40px;
            }

            .advice__text {
                margin-left: 10px;

                p {
                    margin: 0;
                    padding: 0;
                }
            }
        }
    }
`