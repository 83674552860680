import React from "react"
import { Link, graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import { useMember } from "@memberstack/react"

import Layout from "../components/layout"
// import Sidebar from "../components/Sidebar"

import ToolsList from "../components/toolsList"
import SeO from "../components/seo"
import StartCheckout from '../components/startCheckout'
import PrintBlock from "../components/printBlock"

import { offers } from "../utils/pricings"

import { css } from '@emotion/react'
import { colors } from '../styles/constants'

const Article = (props) => {
  const { member } = useMember()
  const year = new Date().getFullYear()
  let step_count = 0

  const post = props.data.article.data
  const author = post.author.document ? post.author.document.data : null
  const tools = post.tools.map( ({tool}) => {
    if ( tool.document !== null && tool.document.uid ) {
      return ({
          'uid': tool.document.uid,
          'name': tool.document.data.name.text,
          'logo': tool.document.data.logo.gatsbyImageData,
      })
    }
    return false
  })

  return (
    <Layout noContainer>
        <SeO 
            title={post.title.text} 
            description={post.desc} 
        />

        <div className="container">
            <div css={header}>
                <h1>{post.title.text}{post.add_year && ` en ${year}`}</h1>
                <p className="content-intro">{post.desc}</p>
            </div>

            <div css={articleMeta}>
                <div>
                    {author && 
                        <div className="article-meta__author">
                            <GatsbyImage image={author.avatar.gatsbyImageData} alt={post.title.text} />
                            {author.name.text.split(" ")[0]}
                        </div>
                    }
                    <div>Dernière update le {props.data.article.last_publication_date}</div>
                </div>
                <div>
                    {tools && <ToolsList tools={tools} />}
                </div>
            </div>
        </div>


        {/**
            <Sidebar table={post.tableOfContents} baseUrl={post.frontmatter.slug} />
        */}

        <div className="article__content">
          {post.body.map( (slice, index) => {
            // If it's a step, then up the count
            slice.slice_type === "step" && step_count++

            // If it's a freebie, then full access
            if ( post.freebie === true ) {
              return <PrintBlock slice={slice} step={step_count} />
            }

            // If not a member, or unpaid member, there are restrictions
            if ( !member || member?.planConnections.length === 0 ) {     
              // Hide intro video     
              if ( index <= 2 && slice.slice_type === "video" ) {
                return null
              }

              // Hide blocks if we're past the 2nd one
              if ( index > 2 ) {
                return null
              }
            }

            // Else you can print the block!
            return <PrintBlock slice={slice} step={step_count} />
          })}
        </div>

        {post.freebie !== true && !member &&
          <div css={paywallBanner}>
              <div className="container">
                <h2>✋ Continuer la lecture ?</h2>
                <p>Cet article est réservé aux membres de Video Lab ! Si vous souhaitez commencer votre formation vidéo avec nous, abonnez vous à notre programme, et apprenez en quelques jours à booster vos résultats digitaux par la vidéo !</p>
                <Link to="/inscription" className="button--primary">Voir les formules</Link>
              </div>
          </div>
        }

        {member?.planConnections.length === 0 && post.freebie !== true &&
          <div className="container">
            <h2>✋ Continuer la lecture ?</h2>
            <p>Cet article est réservé aux membres premium de Video Lab ! Si vous souhaitez commencer votre formation vidéo avec nous, abonnez vous à notre programme ci-dessous, et apprenez en quelques jours à booster vos résultats digitaux par la vidéo !</p>
            <StartCheckout 
                title="Vous n'avez pas encore de plan !"
                text="Débloquez le contenu complet de votre formation vidéo en reprenant votre inscription"
                plans={offers.filter( offer => offer.id === "essentiel" )}
            />
          </div>
        }

    </Layout>
  )
}

export default Article

const header = css`
    max-width: 770px;

    h1 {
        padding: 100px 0 40px;
    }

    p {
        padding-bottom: 0px;
        margin-bottom: 40px;
    }
`

const articleMeta = css`
    padding-top: 18px; 
    padding-bottom: 18px;
    margin-bottom: 50px;
    border-top: 1px solid ${colors.whitegrey}; 
    border-bottom: 1px solid ${colors.whitegrey}; 
    display: flex;
    justify-content: space-between;
    align-items: center;
    
    > div {
        display: flex;
        justify-content: flex-end;
        align-items: center;
    }

    .article-meta__author {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        padding-right: 20px;
        margin-right: 20px;
        border-right: 1px solid ${colors.whitegrey};

        .gatsby-image-wrapper {
            margin-right: 10px;
        }
    }
`

const paywallBanner = css`
    padding: 88px 0;
    background-color: ${colors.tv1};

    h2 {
      margin-top: 10px;
    }
`

export const query = graphql`
    query ArticleQuery($uid: String!) {
        article: prismicArticle(uid: {eq: $uid}) {
            uid
            last_publication_date(formatString: "DD MMMM YYYY", locale: "fr-FR")
            data {
                title {
                    text
                }
                desc
                freebie
                add_year
                body {
                    ... on PrismicArticleDataBodyRichText {
                      id
                      slice_type
                      primary {
                        rich_text {
                          html
                        }
                      }
                    }
                    ... on PrismicArticleDataBodyStep {
                        id
                        slice_type
                        primary {
                          rich_text {
                            html
                          }
                          step_title {
                            text
                          }
                        }
                    }
                    ... on PrismicArticleDataBodyImage {
                        id
                        slice_type
                        primary {
                          image {
                            gatsbyImageData
                            alt
                          }
                        }
                    }
                    ... on PrismicArticleDataBodyVideo {
                        id
                        slice_type
                        primary {
                          embed {
                            html
                          }
                        }
                    }
                    ... on PrismicArticleDataBodyQuote {
                        id
                        slice_type
                        primary {
                          quote {
                            html
                          }
                          source
                          source_url {
                            url
                          }
                        }
                    }
                    ... on PrismicArticleDataBodyAdvice {
                        id
                        slice_type
                        items {
                          advice {
                            html
                            text
                          }
                          type
                        }
                    }
                    ... on PrismicArticleDataBodyQuestion {
                        id
                        primary {
                          question {
                            document {
                              ... on PrismicQuestion {
                                ...QuestionSummary
                              }
                            }
                          }
                        }
                        slice_type
                    }
                    ... on PrismicArticleDataBodyGear {
                        id
                        slice_type
                        items {
                          gear {
                            document {
                              __typename
                              ... on PrismicGear {
                                id
                                uid
                                gear_data: data {
                                  name {
                                    text
                                  }
                                  desc {
                                    text
                                  }
                                  link {
                                    url
                                  }
                                  price
                                  currency
                                  images {
                                    image {
                                      gatsbyImageData
                                    }
                                  }
                                }
                              }
                              ... on PrismicTool {
                                uid
                                id
                                tool_data: data {
                                  name {
                                    text
                                  }
                                  desc
                                  link {
                                    url
                                  }
                                  prices {
                                    plan_name
                                    price
                                    currency
                                    monthly
                                  }
                                  cover {
                                    gatsbyImageData
                                  }
                                }
                              }
                            }
                          }
                        }
                    }
                }
                author {
                    document {
                      ... on PrismicAuthor {
                        uid
                        data {
                          name {
                            text
                          }
                          avatar {
                            gatsbyImageData(width: 66)
                          }
                        }
                      }
                    }
                }
                tools {
                    tool {
                      document {
                        ... on PrismicTool {
                          uid
                          data {
                            logo {
                              gatsbyImageData(width: 66)
                            }
                            name {
                              text
                            }
                          }
                        }
                      }
                    }
                }
            }
        }
    }
`