import React from "react"

import { css } from '@emotion/react'

const VideoSlice = (props) => {
    const {
        html
    } = props

    return (
        <div className="container" data-ms-content="member" css={videoEmbed}>
            <div 
                className="video-embed-container" 
                dangerouslySetInnerHTML={{ __html: html }}
            />
        </div>
    )
}

export default VideoSlice

const videoEmbed = css`
    margin-top: 66px;
    margin-bottom: 66px;
`