import React from "react"

import QuestionCard from "../../components/questionCard"

import { css } from '@emotion/react'
import { colors } from '../../styles/constants'

const QuestionSlice = (props) => {
    const {
        question,
    } = props

    return (
        <div css={questionSlice}>
            <div className="container">
                <QuestionCard 
                    title={question.title.text}
                    tldr={question.tldr}
                    goals={question.goals[0].document ? question.goals.map( goal => (
                        <span>{goal.document.data.name.text}</span>
                    )) : null}
                />
            </div>
        </div>
    )
}

export default QuestionSlice

const questionSlice = css`
    background-color: ${colors.tv4};
    padding-top: 100px;
    padding-bottom: 100px;
    margin-top: 60px;
`