import React from "react"

import { GatsbyImage } from "gatsby-plugin-image"

const ImageSlice = (props) => {
    const {
        image,
        alt,
    } = props

    return (
        <div 
            className="container"
        >
            <GatsbyImage image={image} alt={alt} />
            {alt && <figcaption>{alt}</figcaption>}
        </div>
    )
} 

export default ImageSlice